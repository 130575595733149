define(['requireHelper'], function(requireHelper) {

  var siteObj = {
    siteCode: 'testSite',
    siteIsMobile: false,
    siteTheme: 'testSite'
  };

  describe('require js setup', function() {

    it('should return object', function() {
      expect(new requireHelper(siteObj)).toEqual(jasmine.any(Object));
    });

    describe('resolveLocalPath', function() {

      it('should resolve ../ in module paths', function() {

        //A
        var r = new requireHelper(siteObj);

        //A
        var result = r.resolveLocalPath('/cdn/test/../sessionSettings');

        //A
        expect(result).toEqual('/cdn/sessionSettings');
      });

      it('should resolve module paths without ../', function() {

        //A
        var r = new requireHelper(siteObj);

        //A
        var result = r.resolveLocalPath('/cdn/test/modules/sessionSettings');

        //A
        expect(result).toEqual('/cdn/test/modules/sessionSettings');
      });

      it('should resolve module in root folder', function() {

        //A
        var r = new requireHelper(siteObj);

        //A
        var result = r.resolveLocalPath('/cdn/test/sessionSettings');

        //A
        expect(result).toEqual('/cdn/test/sessionSettings');
      });

      it('should resolve long module paths without ../', function() {

        //A
        var r = new requireHelper(siteObj);

        //A
        var result = r.resolveLocalPath('/cdn/test/modules/product-details/product-details');

        //A
        expect(result).toEqual('/cdn/test/modules/product-details/product-details');
      });

      it('should resolve long module paths without ../', function() {

        //A
        var r = new requireHelper(siteObj);

        //A
        var result = r.resolveLocalPath('/cdn/test/modules/product/../../product-details');

        var res =
        //A
        expect(result).toEqual('/cdn/test/product-details');
      });
    });

    describe('getUpdatedPath', function() {

      it('should return the original script if not found in manifest', function() {

        //A
        var r = new requireHelper(siteObj);

        //A
        var result = r.getUpdatedPath('module/script', {});

        //A
        expect(result).toEqual('module/script');
      });

      it('should return the modified common script if only common found in manifest', function() {

        //A
        var r = new requireHelper(siteObj);

        //A
        var result = r.getUpdatedPath('modules/script', {
          'common/scripts/modules/script.js': 'common/scripts/modules/script-12345678.js'
        });

        //A
        expect(result).toEqual('common/scripts/modules/script-12345678');
      });

    });
  });
});
